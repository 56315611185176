.textLoop {
    font-weight: 600;
    font-size: 70px;
    display: flex;
    gap: 12px;
    color: Black;
    line-height: 68px;
    justify-content: center;  

    @media (max-width: 960px) {
        text-align: center;
    }

    @media (max-width: 640px) {
        font-size: 22px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}
.discr {
    font-size: 18px;
    text-align: center;
    color: black;
    margin:22px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
}
.btnLink{
    border: 1px solid red;
  justify-content: center;
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  color: white;
  height: 70%;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.6s ease-in-out;   
}
.span{
    -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
}