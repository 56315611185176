
/* To add space for the fixed header */
.headerSpace {
    margin-top: 100px; /* Add space to prevent content from hiding under the fixed navbar */
  }
  
  /* Grid container for industries */
  .container {
    width: 80%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Industry Cards */
  .industryCard {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
  }
  
  .industryCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .industryCard img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .industryCard h2 {
    margin: 15px 0 10px;
    color: #333;
  }
  
  .industryCard p {
    line-height: 1.6;
    color: #555;
  }
  .textLoop {
    font-weight: 600;
    font-size: 50px;
    display: flex;
    gap: 12px;
    color: Black;
    line-height: 68px;
    justify-content: center; 
    margin: 22px; 

    @media (max-width: 960px) {
        text-align: center;
    }

    @media (max-width: 640px) {
        font-size: 22px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}
.span{
    -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
}