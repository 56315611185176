.btnLink{
    border: 1px solid red;
  justify-content: center;
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  color: white;
  height: 70%;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.6s ease-in-out;   
}
.textLoop {
    font-weight: 600;
    font-size: 40px;
    display: flex;
    gap: 12px;
    color: Black;
    line-height: 68px;
    justify-content: center;  
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 960px) {
        text-align: center;
        font-size: 20px;
    }

    @media (max-width: 640px) {
        font-size: 14px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}
.searchBar {
   width:40vw;
   margin-left:22px;
   border:2px solid black;
   border-radius:10px;
    @media (max-width: 960px) {
        width:60vw;
    }

    @media (max-width: 640px) {
        width:90vw;
    }
}
.span{
    -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
}
.careerBody{
    background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
    margin-bottom: 30px;
}
.btn{
    margin-top: 90px;
    @media (max-width: 960px) {
        margin-top: 40px;
    }
}
.modalBox{
    background: white;
}
.filterBtn{
    background:-webkit-linear-gradient(225deg, hsla(259, 100%, 50%, 1) 0%, hsla(489, 100%, 50%, 1) 100%)    
}