
/* Grid container for blogs */
.container {
    width: 80%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Blog Post Cards */
  .blogPost {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blogPost:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  
  .blogPost img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .blogPost h2 {
    margin: 15px 0 10px;
    color: #333;
  }
  
  .blogMeta {
    font-size: 14px;
    color: #888;
    margin-bottom: 10px;
  }
  
  .blogPost p {
    line-height: 1.6;
    color: #555;
  }
  .textLoop {
    font-weight: 600;
    font-size: 50px;
    display: flex;
    gap: 12px;
    color: Black;
    line-height: 68px;
    justify-content: center; 
    margin: 22px; 

    @media (max-width: 960px) {
        text-align: center;
        font-size: 35px;
    }
  
    @media (max-width: 640px) {
        font-size: 25px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}
.span{
    -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
}
.btnLink{
border: 1px solid red;
  justify-content: center;
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  color: white;
  height: 70%;
  border-radius: 20px;
  cursor: pointer;
  padding: 0 20px;
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  white-space: nowrap;
  transition: all 0.6s ease-in-out; 
  margin-top: 5px;  
}