.heading{
    font-size: 24px;
    @media (max-width: 960px) {
        font-size: 20px;
    }
    @media (max-width: 640px) {
        margin-top: 30px;
        font-size: 18px;
    }
}
.subHeading{
    font-size: 22px;
    margin: 8px;
    @media (max-width: 960px) {
        font-size: 16px;
    }
    @media (max-width: 640px) {
       
        font-size: 14px;
    }
}
.textfeild{
    font-size: 20px;
    @media (max-width: 960px) {
        font-size: 16px;
    }
    @media (max-width: 640px) {
        font-size: 12px;
    }
}