.alignCenter{
    text-align: center;
    color: black;
}
.footerHeading{
    margin: 25px;
    font-size: 21px;
    font-weight: 800;
}
.footerItemHeading{
    margin: 10px;
    font-size: 20px;
    font-weight: 600;   
}
.footerItemHeading:hover {
    color: blue;
}
a{
    text-decoration: none;
    color: black;
}
a:hover {
    color: blue;
}
